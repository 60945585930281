<template>
  <div class="itinerary">
    <div class="itineray__title">
      {{ t('title') }}
    </div>

    <div class="horizontal-scroll">
      <div class="horizontal-scroll__body">
        <div class="hero-upcoming-trips__body">
          <ProductCard
            class="itinerary__card"
            v-for="product in products"
            :key="product.id"
            :product="product"
          />
        </div>
      </div>
    </div>

    <div class="itinerary__more">
      <a class="itinerary__more-button button" :href="exploreUrl">
        {{ t('see_more') }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { useShared } from "@/helpers/vueHelpers.js";
import { deserializeJsonApi } from '@/utils/jsonApiDeserializer.js';
import ProductCard from '@/modules/Explore/ProductCard';
import { onMounted, ref } from "vue";
import { metaTagContent } from "@/helpers/documentHelpers.js";

defineOptions({name: 'ItineraryIndex'});
const { t } = useShared();
const products = ref([]);
const exploreUrl = metaTagContent('menuExploreUrl');

onMounted(async () => {
  const result  = JSON.parse(document.getElementById('upcoming-trips-json').innerHTML);
  const allEvents = await deserializeJsonApi(result);
  products.value = allEvents.map(event => {
    const product = { ...event.product };
    product.event = event;
    return product;
  });
});
</script>

<style lang="scss" scoped>
$line-height-1: 36px;
$line-height-2: 40px;

.hero-upcoming-trips__body {
  @media (min-width: $breakpoint-m) {
    column-gap: $spacing-6 !important;
  }
}
.itinerary {
  grid-column: start / end;
  @media (min-width: $breakpoint-m) {
    grid-column: left / right;
  }
}
.itineray__title {
  padding-left: $spacing-6;
  margin-bottom: $spacing-6;
  font-size: $text-2xl;
  line-height: $line-height-1;
  @media (min-width: $breakpoint-m) {
    padding-left: 0;
    margin-bottom: $spacing-8;
    font-size: $text-4xl;
    line-height: $line-height-2;
  }
  font-weight: $font-bold;
}
.itinerary__card {
  width: 285px;
  @media (min-width: $breakpoint-m) {
    width: unset;
  }
}
.hero-upcoming-trips__body {
  column-gap: $spacing-2;
  @media (min-width: $breakpoint-m) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: $spacing-2;
  }
}
.itinerary__more {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: $spacing-6;
  @media (min-width: $breakpoint-m) {
   margin-top: $spacing-8;
  }
}
.itinerary__more-button {
  width: fit-content;
  padding: $spacing-3 $spacing-5;
  border: 1px solid $brand-tertiary;
  border-radius: $rounded-xs;
  font-size: $text-base;
  font-weight: $font-semibold;
  color: $brand-tertiary;
}
.itinerary__more-button:hover {
  color: $brand-tertiary;
}
</style>

<i18n lang="yaml">
en:
  title: Upcoming trips
  see_more: See more
</i18n>
